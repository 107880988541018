.main {
    text-align: center;
    background-color: #30344D;
    padding-bottom: 10vh;
    min-width: 500px;
    min-height: 100vh;
    @media screen and (max-width: 550px) {
      width:100vw;
      
    }
  }
  