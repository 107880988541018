.App {
  text-align: center;
  background-color: #929292;
  padding-bottom: 10vh;
  min-width: 500px;
  min-height: 90vh;
  @media screen and (max-width: 550px) {
    width:100vw;
    
  }
}
