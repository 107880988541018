.sketch_board {
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .action {
    button {
      margin: 10px;
      background-color: #cfcece;
      border-radius: 20px;
      border: none;
      padding: 0.5rem 1rem;
    }
    .form-control {
      margin: 10px;
      background-color: #cfcece;
      border-radius: 20px;
      border: none;
    }
  }
  div {
    margin: 0 auto;
  }
}

.popup-content {
  margin: 0;
  background: rgb(255, 255, 255);
  width: 100vw;
  padding: 5px;
  bottom: 0;
  border-radius: 10px;
  border: 1px solid #cfcece;
  position: fixed;
  max-width: 500px;
}
.modal {
  font-size: 12px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background: #ffffff;
  .content {
    box-shadow: 0px 10px 17px -1px rgba(0, 255, 202, 0.55);
    -webkit-box-shadow: 0px 10px 17px -1px rgba(0, 255, 202, 0.55);
    -moz-box-shadow: 0px 10px 17px -1px rgba(0, 255, 202, 0.55);
    border-radius: 20px;
    margin: 20px;
    width: 80%;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
  }
  .actions {
    .name_input {
      margin: 10px;
      background-color: #cfcece;
      border-radius: 20px;
      border: none;
    }
    .submit {
      margin: 10px;
      background-color: #cfcece;
      border-radius: 20px;
      border: none;
      padding: 0.5rem 1rem;
    }
  }
  .header {
    font-size: large;
    margin:15px;
  }
}

input {
  width: 70%;
  height: 50px;
  border-radius: 20px;
  margin-bottom:20px;
}
// .modal > .header {
//   width: 100%;
//   border-bottom: 1px solid gray;
//   font-size: 18px;
//   text-align: center;
//   padding: 5px;
// }
// .modal > .content {
//   width: 100%;
//   padding: 10px 5px;
// }
// .modal > .actions {
//   width: 100%;
//   padding: 10px 5px;
//   margin: auto;
//   text-align: center;
// }
.modal > .close {
  cursor: pointer;
  position: absolute;
  display: block;
  padding: 10px 13px;
  line-height: 20px;
  right: 20px;
  top: 20px;
  font-size: 24px;
  background: #ffffff;
  border-radius: 30px;
  border: 1px solid #cfcece;
}

@media screen and (max-width: 768px) {
  .modal {
    position: fixed;
    bottom: env(safe-area-inset-bottom);
    width: 100%;
    margin: 0 auto;
    background-color: #ffffff;
    left: 0;
    height: 80vh;
    border-radius: 1.5rem 1.5rem 0 0;
  }
  .popup-content {
    margin: 0;
    background: rgb(255, 255, 255);
    width: 100vw;
    padding: 5px;
    bottom: 0;
    position: fixed;
  }
  .popup-arrow {
    color: rgb(255, 255, 255);
  }
  [role="tooltip"].popup-content {
    width: 100vw;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 3px;
  }

  .popup-overlay {
    background: rgba(0, 0, 0, 0.5);
  }
  [data-popup="tooltip"].popup-overlay {
    background: transparent;
  }
}
