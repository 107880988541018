.header {
    margin-top:0px;
    margin-bottom:0px;
    h1 {
        margin: 0px;
        color: #fff;
    }
    h2 {
        color: #fff;
    }
}